//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-172:_4808,_7260,_5292,_3279,_456,_8368,_1568,_2316;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-172')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-172', "_4808,_7260,_5292,_3279,_456,_8368,_1568,_2316");
        }
      }catch (ex) {
        console.error(ex);
      }